* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Pompiere", sans-serif; */
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Comfortaa", sans-serif;
}
body {
  font-size: 13px !important;
  transition: 0.6s ease-in all;
}
.transition {
  transition: 0.6s ease all;
}
img {
  max-width: 100%;
  height: auto;
}
.App {
  /* min-height: 100vh;
  width: 100%;
  max-width: 1900px;
  margin: 0rem auto; */
  background: #fff;
  min-height: 100vh;
}

.App.light {
  /* background: #f2ede2; */
}

.App.dark {
  background: #222;
}

.ptb-5 {
  padding: 3rem 0px !important;
}

body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: #fff !important;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* min-height: 90px; */
}
.navbar-menu-container {
  display: none;
}
.navbar-links-container a {
  margin-left: 3rem;
  text-decoration: none;
  color: #777 !important;
  font-size: 1.1rem;
}
p.nav-brand {
  font-size: 16px;
  width: 100%;
  margin-bottom: 0;
  /* text-align: center; */
  font-weight: 400;
  letter-spacing: 1px;
}

p.nav-brand.dark {
  color: #fff;
}

p.nav-brand.light {
  color: #fff;
}

.primary-button {
  padding: 7px 20px;
  background: #000;
  outline: none;
  border: none;
  border-radius: 7px;
  font-size: 12px;
  cursor: pointer;
  font-weight: 600;
  transition: 0.6s;
  color: #fff !important;
  box-shadow: 5px 5px 10px #0000000f;
  text-decoration: none;
}
.primary-button:hover {
  background: #111;
  box-shadow: inset 10px 10px 10px -9px rgb(136 136 136 / 38%);
  color: #c82b60 !important;
}

.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 3rem;
}
.home-bannerImage-container {
  position: absolute;
  top: -100px;
  right: -170px;
  z-index: -2;
  max-width: 700px;
}
.home-image-section {
  max-width: 600px;
  flex: 1;
}
.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.primary-heading {
  font-size: clamp(2rem, 5vw, 4rem);
  color: #4c4c4c;
  /* line-height: 5rem; */
  max-width: 600px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar:vertical {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c82b60;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c82b60;
}

.nav-logo-container a {
  text-decoration: none;
}

.btn-primary {
  background-color: #cc2b5e !important;
}

@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .primary-button {
    /* font-size: 1rem; */
  }
  .home-bannerImage-container {
    max-width: 600px;
  }
}
@media (max-width: 800px) {
  .nav-logo-container {
    max-width: 140px;
  }
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
    font-size: 26px;
  }
  .home-bannerImage-container {
    max-width: 450px;
  }
}
@media (max-width: 768px) {
  .home-bannerImage-container,
  .about-background-image-container {
    display: none;
  }
}
.d-flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* text-align: center; */
}

.ptb-2 {
  padding: 30px 0px;
}

hr {
  border-top: 0.5px solid #000000 !important;
  margin: 5px 0px 0px !important;
}

a {
  color: #c82b60 !important;
}