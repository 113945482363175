// Responsive design CSS.

@media (min-width:800px) and (max-width:995px) {
  .navbar-links-container {
    a {
      margin-left: 1rem !important;
    }
  }

}

@media (max-width: 768px) {
  .themebtn {
    position: absolute;
    right: 14px;
  }
  .navbar-menu-container {
    position: absolute;
    right: 64px;
  }
  h2 {
    font-weight: 400 !important;
    font-size: 16px !important;
    letter-spacing: 2px;
  }
  h3 {
    font-size: 14px !important;
  }

  a.btn {
    font-size: 12px;
  }

  .card.nm {
    flex-direction: column !important;
  }
  .header {
    width: 100%;
    padding: 20px 0px;
  }
  .space-2 {
    gap: 20px;
  }
  .ptb-5 {
    padding: 2rem 0px;
  }
  .advice {
    h1 {
      font-size: 22px;
    }
  }

  .mob-mb-3 {
    margin-bottom: 30px;
  }

  .my-works {
    a {
      font-size: 14px !important;
    }
    h2 {
      padding: 0px 50px 0px 0px !important;
    }
  }
  .about {
    p {
      font-size: 14px;
    }
    img {
      &.niki {
        // border-radius: 50%;
        filter: grayscale(1);
        // width: 60%;
        margin: auto;
        text-align: center;
        /* display: flex; */
        // height: 70%;
        object-fit: cover;
      }
    }
  }

  .footer {
    position: relative;
    p {
      &.d-flex {
        flex-direction: column;
      }
      span {
        &.copytext {
          font-size: 12px;
          padding: 15px;
        }
      }
      i {
        position: relative;
        bottom: 0px;
        font-size: 12px;
      }
    }
  }

  .about {
    .home-area {
      // text-align: center;
      h2 {
        margin: auto;
        padding-bottom: 15px;
      }
      .iconbox {
        // justify-content: center;
      }
    }
    .social-icons {
      .d-flex {
        flex-direction: column;
        gap: 4px !important;
      }
    }
  }

  p {
    &.nav-brand {
      width: 100%;
      font-size: 16px;
    }
  }

  .tab-area {
    .tabs {
      //   justify-content: center;
      button {
        padding: 5px 10px;
        line-height: 18px;
      }
    }
    .tabs-content {
      text-align: left;
      min-height: 0px;
      .education {
        &.mob-text-center {
          text-align: center;
        }
        .icon {
          display: none;
        }
        .cont {
          width: 100%;
          .edu {
            gap: 15px;
            .year {
              width: 30%;
            }
            .edu-area {
              width: 70%;
            }
          }
        }
      }
    }
  }

  .services {
    .card {
      min-height: 135px;
      .titl {
        p {
          font-size: 14px;
        }
      }
    }
  }

  .float-icon {
    position: initial;
    margin-top: 10px;
    border-radius: 10px;
    .float {
      z-index: 999;
      gap: 5px;
      position: initial;
      rotate: 0deg;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      h3 {
        font-size: 18px !important;
        text-align: left;
      }
      a {
        font-size: 10px;
        padding: 5px 10px;
        letter-spacing: 2px;
        font-weight: 500;
        margin-right: 0px;
        padding-left: 0px;
        svg {
          margin-left: 3px;
        }
      }
    }
  }

  .d-flex-between-center {
    i {
      font-size: 20px;
      line-height: 0px;
    }
  }

  .mob-flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .pt-7,
  .pb-5 {
    padding-top: 1rem !important;
  }

  .items {
    .title {
      h3 {
        font-weight: 400;
        span {
          font-size: 16px;
        }
      }
    }
  }

  .detailed-page {
    p {
      font-size: 14px;
    }
  }
  hr {
    margin-top: 10px !important;
  }
  .ptb-5 {
    padding: 2rem 0px !important;
  }
  .pt-5 {
    padding-top: 2rem !important;
  }

  .services {
    .card {
      min-height: 150px;
    }
  }

  .intro {
    font-size: 14px !important;
  }
}

@media (min-width: 770px) and (max-width: 1190px) {
  .services {
    .card {
      min-height: 170px;
    }
  }
}

@media (max-width: 1990px) {
  .works .card img.mobile-app {
    height: auto;
  }
}